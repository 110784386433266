const state = {
  SessionKey: localStorage.getItem('SessionKey') || '',
  UserID: localStorage.getItem('UserID') || '',
  Position: localStorage.getItem('Position') || '',
  managerCode: '',
  isLoading: false,
  refLink:'',
  whiteLink:['/insurance'],
  ZO:localStorage.getItem('ZO') || '',
  RC_NAME:'',
  postionArray : [355,763,713,389,350,262,281],
  positionArrayTop: [355,713,389,350,99,281],
  workers:[],
};

//isAuthenticated вернет false если нет ключа, true если он есть. проверка ключа проводится в процедурах взаимодействия.
const getters = {
  isAuthenticated: state => {
    let SessionKeyCreate = (localStorage.getItem('SessionKeyCreate')  != undefined) ?
      parseInt(localStorage.getItem('SessionKeyCreate'), 10) : 0;
    let currentTime = new Date();

    let SessionNeedRestart = (SessionKeyCreate == 0) ?
       true : ((currentTime.getTime() - SessionKeyCreate) / 1000 / 60 / 60 / 24) >= 1 ?
        true: false;
    return !!state.SessionKey && !!state.UserID && !!state.Position && !SessionNeedRestart;
  },
  userFromSalesManagers: state =>{
    return state.postionArray.includes(parseInt(state.Position,10));
  },
  userFromSalesManagersTop: state=>{
    return state.positionArrayTop.includes(parseInt(state.Position,10));
  },
  getActiveWorkers: state => {
    //вернет сотрудников в статусе работает
    let out = [];
    state.workers.forEach((item)=>{
      if (item.manual_block == 0 && item.staff_id != parseInt(state.UserID, 10)) {
        out.push(item)
      }
    });
    if (out.length > 0) {
      out.sort((a,b)=>{
        if (a.displayname.toLocaleLowerCase() > b.displayname.toLocaleLowerCase()) return 1;
        if (a.displayname.toLocaleLowerCase() < b.displayname.toLocaleLowerCase()) return -1;
        return  0;
      })
    }
    return out;
  }
};

const actions = {
  refLinkSave:({commit}, refLink) => {
    try{
      //проверяем может ли данная сылка провалиться после авторизации
      //и не находимся ли мы на уже этой странице
      if (state.whiteLink.indexOf(refLink.pathname) >= 0  && state.whiteLink.indexOf(refLink.fromPath) < 0 ) {
        commit('setRefLink', refLink.pathname + refLink.search);
      }
    }catch (e) {
      return false;
    }
  },
  setWorkers:({commit}, arrWorkers) => {
    if (Array.isArray(arrWorkers)){
      let resultArray = [];
      arrWorkers.forEach((item)=>{
        if (parseInt(item.staff_id, 10) != parseInt(state.UserID,10)) {
          let out = {};
          out.displayname = (item.displayname != undefined) ? item.displayname : 'Нет фио';
          out.manual_block = (item.manual_block != undefined && item.manual_block == '0') ? 0 : 1; //0 все ок, 1 -
          // заблокирован
          out.manager_Code = (item.manager_Code != undefined) ? item.manager_Code : 'Не указан код';
          out.staff_id = (item.staff_id != undefined) ? parseInt(item.staff_id, 10) : -1;
          out.selfAgent = (item.selfAgent != undefined && parseInt(item.selfAgent, 10) == 1) ? 1 : 0;
          out.ZO_ID = (item.ZO_ID != undefined && parseInt(item.ZO_ID, 10) > 0) ? parseInt(item.ZO_ID, 10) : 0;
          resultArray.push(out);
        }
      });
      if (resultArray.length > 0) {
        commit('saveWorkers',resultArray);
      }
    }
  },
};

const mutations = {
  saveWorkers: (state,value) => {state.workers = value},
  setZO: (state,value) => {
    localStorage.setItem('ZO', value);
    state.ZO = value;
  },
  setUserID: (state, value) => {
    localStorage.setItem('UserID', value);
    state.UserID = value;
  },
  setSessionKey: (state, value) => {
    localStorage.setItem('SessionKey', value);
    let currentTime = new Date();
    localStorage.setItem('SessionKeyCreate', currentTime.getTime());
    state.SessionKey = value;
  },
  setManagerCode: (state, value) => {
    state.managerCode = value;
  },
  setPosition: (state, value) => {
    localStorage.setItem('Position', value);
    state.Position = value;
  },
  loadingStatus: (state, value) => {
    state.isLoading = value;
  },
  setRefLink: (state,value) => {
    state.refLink = value;
  },
  setRC_NAME:(state,value) =>{
    state.RC_NAME = value
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
}
