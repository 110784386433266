<template>
  <div id="app" class="grid-container" v-bind:class="{outlogin:!isAuthenticated, loading:isLoading}">
    <div class="nav" v-if="isAuthenticated">
      <router-link to="/">
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8h5z"/></svg>
        <span>Главная</span>
      </router-link> |
      <router-link to="/menupage">
        <svg width="20" height="13" viewBox="0 0 20 13" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M0 10.5v2h20v-2H0zm0-5v2h20v-2H0zm0-5v2h20v-2H0z"/></svg>
        <span>Меню</span>
      </router-link> |
      <p>Ver. {{versionText}}</p>
    </div>
    <div v-bind:class="hideAsideClass"
         class="content mainContent">
      <router-view/>
    </div>
    <div v-if="isAuthenticated && !asideIndex"
         v-bind:class="hideAsideClass"
         class="content aside"
    >
      <asideC></asideC>
    </div>
    <loadingSpinner v-if="isLoading"></loadingSpinner>
  </div>
</template>
<script>
  import {mapGetters, mapState} from 'vuex';

  export default {
    name: 'app',
    data() { return {} },
    beforeCreate(){
      this.$store.dispatch('checkDomainName');
    },
    mounted() {
      if (localStorage.getItem('devGuid') == null) {
        localStorage.setItem('devGuid', this.$kostili.uuidv4());
      }
      this.$store.dispatch('geoInit');
      this.checkVisibilityPayment();

      try {
        if (this.isAuthenticated) {
          this.$myApi.singleRequest({
            action: 'getUserMotivationList',
            type: 'motivation'})
          .then((res) => {
            this.$store.dispatch('setWorkers', res.userList);
            this.$store.commit('setRC_NAME', res.RC_NAME)
          }).catch(res => console.log(res));
        }
      } catch (e) {
        console.log(e)
      }
    },
    methods:{
      checkVisibilityPayment(){
        this.$myApi.singleRequest({
          action:'isActivePaymentPage',
          type:'payment'
        }).then((res)=>{
          localStorage.setItem('payment',res.visibility);
        }).catch((res)=>{
          console.log(res);
        })
      },
      checkAside(){
        return this.asideList.findIndex((item)=>{
          return item.name == 'showAside' && item.status;
        }) >= 0;
      }
    },
    components:{
      loadingSpinner: ()=> import('@/components/loader'),
      asideC:         ()=> import('@/views/aside')
    },
    watch:{
      $route(to) { this.$store.dispatch('cdCurrentPageAsideView', to); }
    },
    computed: {
      ...mapGetters(['isAuthenticated']),
      ...mapState({
        to_theme:    state => state.company.to_theme,
        isLoading:   state => state.auth.isLoading,
        refLinkVuex: state => state.auth.refLink,
        asideList:   state => state.aside.asideState.asideList,
      }),
      asideIndex(){
        return  this.asideList.findIndex((item) => {return item.name == 'showAside' && item.status;}) >= 0
      },
      hideAsideClass(){
         let classObj = {
           longContnetn:this.asideIndex
         };
        if (this.to_theme != undefined) {
          classObj[this.to_theme] = true;
        }
        return classObj;
      },
      versionText() {
        return process.env.VUE_APP_VERSION;
      }
    }
  }
</script>
<style>
@import "/src/views/grid/leftBarStyles.css";
  html, body {font-family: Avenir, Helvetica, Arial, sans-serif;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;}
  html, body, ul, li, p, a, h1, h2, h3, input, button {margin: 0;padding: 0;}
  html{font-size: 5vw;}
  ul, li {list-style: none;}
  a {color: var( --middleMarineGreenGeneral ) ;font-weight: bold;text-decoration: underline;transition: 0.3s ease-out;}
  a:hover{color: var( --lightGreenGeneral ) }
  img {display: inline-block;border: 0;outline: none}
  body {background: rgba(15, 87, 64, 0.11);}
  h1{color: var( --middleMarineGreenGeneral ) ;font-size: 7vw;margin-bottom: 4vw;}


  input {display: block;color: var( --blackGreenGeneral ) ;font-size: 5vw;padding: 1.6vw 2vw;background: transparent;border: 1px solid var( --middleMarineGreenGeneral ) ;width: calc(100% - 2px - 4vw);}

  .flex{display: flex;align-items: center;justify-content: space-between}
  .flex.st{justify-content: flex-start}

  #app {padding-bottom: 18vw;}
  .grid-container {display: grid;grid-template-areas: "content" "aside" "nav";}
  .grid-container.outlogin {grid-template-areas: "content";}

  .nav { grid-area: nav; height: 15vw;background: white;border-top:3px solid var( --middleMarineGreenGeneral ) ;display: flex;align-items: center;justify-content: space-around;position: fixed;bottom: 0;width: 100%;z-index: 99;}
  .nav a{display: flex;align-items: center;justify-content: center;flex-direction: column}
  .nav a span{font-size: 3vw}

  .content { grid-area: content; padding: 2vw;}
  .content > div {background: white;border: 1px solid var( --middleMarineGreenGeneral ) ;padding: 2vw;border-radius: 5px;}
  .outlogin .content {background: transparent}
  .aside { grid-area: aside;}
  svg{fill: var( --middleMarineGreenGeneral ) ;outline: none;}
  .borderStyle{border: 1px solid var( --middleMarineGreenGeneral ) ;padding: 2vw;border-radius: 5px;}



  button, .likeAButton{background-color: var( --middleMarineGreenGeneral ) ;color: var( --whiteGeneral )
  ;font-size: 4.3vw;border:1px solid
  var( --lightGreenGeneral ) ;width: 100%;text-transform: uppercase;min-height: 10vw;cursor: pointer;transition: 0.3s ease-out}
  button:hover, .likeAButton:hover{background-color: var(--lightGreenGeneral)}



  #app.loading{pointer-events: none;}
  .subNav{}
  .subNav a{display: flex;align-items: center;margin: 3vw 0;font-weight: normal}
  .subNav a span{}
  .subNav a svg{width: 10vw;}

  .router-link-exact-active{color: var( --lightGreenGeneral ) }
  .router-link-exact-active span{color: var( --lightGreenGeneral ) }
  .router-link-exact-active svg{fill: var( --lightGreenGeneral ) }
  .txtContent{}
  .txtContent p{margin:2vw 0}

  #contdownWrap{}
  #contdownWrap a{margin: 2vw 0;display: inline-block}
  #contdownWrap ul{}
  #contdownWrap ul li{}
  #contdownWrap button{}

  .descLine{position: relative;padding: 0 0 2vw;}
  .descLine p:first-child{font-size: 4vw;color: var( --brightMarineGreenGeneral ) ;}
  .descLine p:last-child{}

  .childListStyle{position: relative}
  .childListStyle:after,.childListStyle:before{content: '';position:absolute;background: var( --lighterMarineGreenGeneral ) ;}
  .childListStyle:before{top:50%;left:-3vw;width: 2vw;height: 1px;}
  .childListStyle:after{top:0;left:-3vw;width: 1px;height: 50%;}

  .errorMsg{color: var(  --redGeneral ) }

  .buttonsStyle{background: linear-gradient(to right,var(--whiteGeneral),var( --alphMarineGreenGeneral));border: solid 1px var(--darkMarineGreenGeneral);border-radius: 5px;}
  .buttonsStyle:hover{background: linear-gradient(180deg,var(--whiteGeneral),var(--alphlightGreenGeneral))}

  .avtoHideScroll_y{overflow: hidden;}
  .avtoHideScroll_y:hover{overflow: scroll;}

  @media screen and (min-width: 760px) {
    #app{padding-bottom: 0}
    .content{padding: 1vw;}
    html{font-size: 2.3vw}
    h1{font-size: 4vw;margin-bottom: 2vw;}

    .mainContent.longContnetn{width: 98vw}

    input {font-size: 2.3vw;padding: 0.5vw 1.5vw; width: calc(100% - 3vw - 2px)}
    button, .likeAButton{font-size: 1.8vw;padding: 0 1vw;min-height: 3vw}
    .nav{height: 4vw;}
    .nav a span{font-size: 1.3vw}
    .errMsg{color: var(  --redGeneral ) ;margin: 1vw 0;}
    #app.loading{pointer-events: none;}
    .subNav{display: flex;align-items: center}
    .subNav a{display: flex;align-items: center;margin: 1vw 0.5vw;max-width: 33%}
    .subNav a span{ }
    .subNav a svg{width: 4vw;}
    .txtContent{}
    .txtContent p{margin: 1vw 0}
    #contdownWrap{}
    #contdownWrap a{}
    #contdownWrap ul{}
    #contdownWrap ul li{}
    #contdownWrap button{}

    .descLine{padding: 0 0 1vw;}
    .descLine p:first-child{font-size: 2vw;color: var( --brightMarineGreenGeneral ) ;}
    .descLine p:last-child{}

    .childListStyle{position: relative}
    .childListStyle:after,.childListStyle:before{content: '';position:absolute;background: var( --lighterMarineGreenGeneral ) ;}
    .childListStyle:before{top:50%;left:-3vw;width: 2vw;height: 1px;}
    .childListStyle:after{top:0;left:-3vw;width: 1px;height: 50%;}

    .borderStyle{}
  }
  @media screen and (min-width: 1200px) {
    html{font-size: 18px;}
    h1{font-size: 24px;margin-bottom: 15px;}
    .ALS{margin: 0;}




    input {font-size: 14px;padding: 2px 0;height: 20px;width: 100%;}
    .nav{position: fixed;border-top:0;border-bottom: 3px solid  var( --middleMarineGreenGeneral ) ;height: 30px;justify-content: flex-start;top:0;}
    .nav a{flex-direction: row;margin: 0 15px;}
    .nav a span{margin-left: 10px;font-size: 16px;}
    .grid-container.outlogin .content{background: transparent}
    .aside{position: fixed;top:40px;right: 1.5vw;width: 30vw;height: calc(100vh - 60px)}
    .aside > div{width: 100%;height: 97%;overflow-y: scroll}
    .mainContent{position: relative;width: 66.5vw;margin-top: 40px;margin-left: 0.5vw;  }
    .mainContent.longContnetn{width: 98vw}
    .aside.longContnetn{pointer-events: none}
    .content > div{padding: 7px;}
    button, .likeAButton{font-size: 18px;min-height: 30px;}
    .errMsg{color: var(  --redGeneral ) ;margin: 5px 0;}
    .content{padding: 0; margin-bottom: 20px;}
    .subNav{flex-wrap: wrap}
    .subNav a{margin: 5px;max-width: 200px;width: auto;font-size: 15px;justify-content: space-between}
    .subNav a span{ }
    .subNav a svg{width: 20px;}
    .txtContent{}
    .txtContent p{margin: 5px 0;}
    #contdownWrap{}
    #contdownWrap a{}
    #contdownWrap ul{}
    #contdownWrap ul li{}
    #contdownWrap button{}
    #contdownWrap a{margin: 10px 0}

    .descLine{padding: 0 0 10px;}
    .descLine p:first-child{font-size: 18px;color: var( --brightMarineGreenGeneral ) ;}
    .descLine p:last-child{}

    .childListStyle{position: relative}
    .childListStyle:after,.childListStyle:before{content: '';position:absolute;background: var( --lighterMarineGreenGeneral ) ;}
    .childListStyle:before{top:50%;left:-10px;width: 10px;height: 1px;}
    .childListStyle:after{top:0;left:-10px;width: 1px;height: 50%;}

    .borderStyle{padding: 10px}
  }

  @import './components/Form/inputBox.css';


  .comboButnWrap {display: flex;justify-content: space-around;align-items: center;}
  .comboButnWrap button{padding: 5px 0;max-width: 40%}
  @media print{
    .nav, .aside {display: none}
    html,body{font-size: 9px;line-height: 9px;  height: auto;}
    p{margin: 0 0 2px}
    td{padding: 1px;}
    .content > div{padding: 0;border: 0}
    .content{margin: 0}
    #app { padding-bottom: 0 }
  }
</style>
