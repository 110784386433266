import axios from 'axios';


export default {
  url: function () {
    return process.env.VUE_APP_API_URL;
  },
  checkExecutionErrorVue: function (response) {
    if(response['Response']['ExecutionError']['ExecutionErrorMessage'] != undefined) {
      //костыль для выбрасывания пользователя из лк
      if (response['Response']['ExecutionError']['ExecutionErrorMessage'].indexOf('816A8108-9D32-4499-ACB0-24E3201E2CA1') >= 0) {
        document.location.href = '/logout';
        return false;
      }
      if (response['Response']['ExecutionError']['ExecutionErrorCode'] == 401) {
        document.location.href = '/logout';
        return false;
      }
    }
    return response['Response']['ExecutionError']['ExecutionErrorCode'] === 0;
  },
  checkErrorCodeVue: function (response) {
    if (response === null) {
      //что то отъебнулось закрываем запрос если повезло с null
      return {result: false, reload: false};
    }
    if (response.Error['ErrorCode'] < 0) {
      // ошибки из фракции полный пипец
      return {result: false, reload: false};
    }
    if (response.Error['ErrorCode'] === 1) {
      // ошибки которые могут пройти если немного подождать
      return {result: true, reload: true};
    }
    if (response.Error['ErrorCode'] > 1) {
      return {result: false, reload: false};
    }
    if (response.Error['ErrorCode'] === 0) {
      // все збс
      let responeResult = true;
      if (Object.prototype.hasOwnProperty.call(response, "Response")) {
        responeResult = this.checkExecutionErrorVue(response);
      }
      if (responeResult) {
        return {result: true, reload: false};
      } else {
        return {result: false, reload: false};
      }
    }
    return {result: false, reload: false}
  },
  SendRequest: function (sendData,serviceName = '') {
    return new Promise((resolve, reject) => {
      let self = this;
      sendData['UserID'] = localStorage.getItem('UserID');
      sendData['SessionKey'] = localStorage.getItem('SessionKey');
      sendData['sessionid'] = localStorage.getItem('sessionid');
      if (serviceName  != '') {
        sendData['sys_name'] = serviceName;
      }

      axios({
        method: 'post',
        url: this.url(),
        data: sendData,
        responseType: 'json',
        headers: {'Content-type': 'application/json'}
      }).then(function (response) {
        // debugger;
        let checkResult = self.checkErrorCodeVue(response.data);
        if (checkResult.result) {
          if (checkResult.reload) {
            const wait = ms => new Promise(resolve => setTimeout(resolve, ms));
            wait(500).then(() => {
              self.SendRequest(sendData)
              .then((res) => {
                resolve(res)
              })
              .catch((res) => {
                reject(res)
              });
            });
          } else {
            try {
              serviceName = (response.data.sys_name != undefined) ?  response.data.sys_name : 'portal_iks';
            }
            catch (e) {
              serviceName = 'portal_iks';
            }


            self.GetRequestResult(response.data['RequestID'],serviceName)
            .then((res) => {
              resolve(res)
            })
            .catch((res) => {
              reject(res)
            });
          }
        } else {
          reject(response.data);
        }
      }).catch(function (error) {
        reject(self.networkError(error, sendData));
      });
    });
  },
  GetRequestResult: function (ExecuteGUID, serviceName = '') {
    return new Promise((resolve, reject) => {
      let self = this;
      let sendData = {
          ExecuteGUID: ExecuteGUID,
          cache: localStorage.getItem('cache'),
        action: 'iks_GetQuery',
        UserID: localStorage.getItem('UserID'),
        SessionKey: localStorage.getItem('SessionKey'),
        sessionid: localStorage.getItem('sessionid'),
        }
      ;
      if (serviceName  != '') {
        sendData['sys_name'] = serviceName;
      }
      axios({
        method: 'post',
        url: this.url(),
        data: sendData,
        responseType: 'json',
        headers: {'Content-type': 'application/json'}
      }).then(function (response) {
        let checkResult = self.checkErrorCodeVue(response.data, 'getResultSaveForm');
        if (checkResult.result) {
          if (checkResult.reload) {
            const wait = ms => new Promise(resolve => setTimeout(resolve, ms));
            wait(2000).then(() => {
              self.GetRequestResult(ExecuteGUID, serviceName)
              .then((res) => {
                resolve(res);
              })
              .catch((res) => {
                reject(res)
              });
            });
          } else {
            resolve(response.data['Response']['ExecutionResult']);
          }
        } else {
          reject(response.data);
        }
      }).catch((resp) => {

        reject(self.networkError(resp, sendData))
        if (resp.data !== undefined) {
          reject(resp.data);
        } else {
          reject(resp);
        }
      });
    });
  },
  singleRequest: function (sendData) {
    sendData['UserID'] = localStorage.getItem('UserID');
    sendData['SessionKey'] = localStorage.getItem('SessionKey');
    sendData['sessionid'] = localStorage.getItem('sessionid');
    return new Promise((resolve, reject) => {
      let self = this;
      axios({
        method: 'post',
        url: this.url(),
        data: sendData
      }).then((resp) => {
        try {
          if (resp.data.Response.ExecutionResult !== undefined) {
            resolve(resp.data.Response.ExecutionResult);
          } else {
            reject(resp.data);
          }
        } catch (resp) {
          reject(resp.data);
        }
      }).catch(function (resp) {
        reject(self.networkError(resp, sendData));
        if (resp.data !== undefined) {
          reject(resp.data);
        } else {
          reject(resp);
        }
      });
    });
  },
  checkVersion: function () {
    return new Promise((resolve, reject) => {
      this.singleRequest({
        action: 'checkVersion'
      }).then((res) => {
        if (localStorage.getItem('cache') === null) {
          localStorage.setItem('cache', res.cache)
        } else if (parseInt(localStorage.getItem('cache'), 10) !== res.cache) {
          localStorage.setItem('cache', res.cache);
         // document.location.reload();
          reject();
        }
        resolve();
      })
      .catch((res) => {
        reject(res);
      });
    });
  },
  suggestResult: function (action,queryValue){
    //отдельный копипаст для дадаты
    return new Promise((resolve, reject) => {
      axios({
        method:'post',
        url:this.url(),
        data:{
          action:action,
          // action:'Dadata_getSuggest',
          query:queryValue,
        }
      }).then((resp)=>{
        try {
          resolve(resp.data.Response.ExecutionResult.suggestions);
        } catch (e) {
          reject();
        }
      }).catch(()=>{
        reject();
      });
    });
  },
  parseError: function (objError, fromAction) {
    // debugger;
    try {
      if (objError.Error.ErrorCode < 0) {
        //что то пошло по пиз** пишем в лог
        return this.upsResponseBroken(objError, fromAction, '');
      }
      if (objError.Response.ExecutionError.ExecutionErrorCode < 0) {
        return this.upsResponseBroken(objError, fromAction, '');
      }
      //возрашаем нормальный код ошибки и сообщение об ошибке
      return {
        ExecutionErrorCode: objError.Response.ExecutionError.ExecutionErrorCode,
        ExecutionErrorMessage: objError.Response.ExecutionError.ExecutionErrorMessage,
      }
    } catch (e) {
      //совсем все пошло по пиз** пишем в лог
      return this.upsResponseBroken(objError, fromAction, e);
    }
  },
  // eslint-disable-next-line no-unused-vars
  upsResponseBroken: function(objError, fromAction, e = {message:''}){
    //логируем что за херня случилась
    // axios({
    //   method: 'post',
    //   url: this.url(),
    //   data: {
    //     action: 'logError',
    //     from: 'lkClient',
    //     catchAction: fromAction,
    //     catchMsg: e.message,
    //     result: JSON.stringify(objError),
    //   }
    // });
    return {
      ExecutionErrorCode: 0,
      ExecutionErrorMessage: 'Мы отследили эту ошибку и уже исправляем. Попробуйте позже. ' + JSON.stringify(e),
    }
  },
  catchRequestsChecking: function(result) {
    try {
      if (result.Response.ExecutionError.ExecutionErrorCode > 0) {
        if (result.Response.ExecutionError.ExecutionErrorCode !== 401) {
          alert(result.Response.ExecutionError.ExecutionErrorMessage);
        }
      } else {
        alert('Ошибка, sys_name=' + result.sys_name
            + ' RequestID=' + result.RequestID
            + ' ExecutionErrorCode=' + result.Response.ExecutionError.ExecutionErrorCode);
      }
    } catch (e) {
      alert(JSON.stringify(result) + ' || ' + 'Ошибка ' + e.name + ":" + e.message + "\n" + e.stack);
    }
  },
  networkError: function(error, data) {
    let message = '';
    let errJson = error.toJSON();
    data.SessionKey = '';
    let dataJSON = JSON.stringify(data);
    errJson['Response'] = {};
    errJson['Response']['ExecutionError'] = {};
    if (error.response) {
      // console.log(error.response);
      console.log('Запрос был сделан, и сервер ответил кодом состояния, который выходит за пределы 2xx');
      errJson.Response.ExecutionError['ExecutionErrorCode'] = error.response.status
      errJson.Response.ExecutionError['ExecutionErrorMessage'] = 'Ошибка в коде, сервер ответил кодом состояния';
      if (data.action === 'login') {
        message = 'Ошибка в коде, сервер ответил кодом состояния' + ' ' + error.response.status + ' ' + 'в методе' + ' ' + data.action;
      } else {
        message = 'Ошибка в коде, сервер ответил кодом состояния' + ' ' + error.response.status + ' ' + 'в методе' + ' ' + data.action + ' ' + 'с данными' + ' ' + dataJSON;
      }
      alert(message)
      // console.log(error.response.data);
      // console.log(error.response.status);
      // console.log(error.response.headers);
    } else if (error.request) {
      // `error.request`- это экземпляр XMLHttpRequest в браузере и экземпляр
      // http.ClientRequest в node.js
      console.log('Запрос был сделан, но ответ не получен');
      if (errJson.message === 'Network Error') {
        errJson.Response.ExecutionError['ExecutionErrorCode'] = 666
        errJson.Response.ExecutionError['ExecutionErrorMessage'] = 'Нет соединения с интернетом, операция не выполена';
      }
    } else {
      console.log('ErrorПроизошло что-то при настройке запроса, вызвавшее ошибку');
      // console.log('Error', error.message);
    }
    // console.log(error.config);
    return errJson
  }
}
