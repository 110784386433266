const state = {
  staff_id: 0,
  selfAgent:0,
  bwid: 0,
  managerMenu: false,
  library:{
    selfAgent:{
      Collect:'Консультации Клиентов Заказчика о порядке осуществления  платежей в пользу Заказчика',
      Collect10:'Сбор по договорам клиентов (акционный пул)',
      Collect15:'',
      Psale25:'Доставка документов от Клиента к Заказчику/ от Заказчика к Клиенту, найденному Заказчиком, с последующей выдачей Заказчиком займов на срок 25нед.',
      Psale:'Доставка документов от Клиента к Заказчику/ от Заказчика к Клиенту, найденному Заказчиком, с последующей выдачей Заказчиком займов на срок 52нед.',
      Attr:'Привлечение новых Клиентов, с последующей выдачей Заказчиком займов на срок 52нед.',
      Attr2: '',
      Amulex:'Привлечение Клиентов, с последующей выдачей Заказчиком дополнительного продукта "Персональный юрист"',
      TeleDoc:'Привлечение Клиентов, с последующей выдачей Заказчиком дополнительного продукта "Телемедицина"',
      VSK:'Привлечение Клиентов, с последующей выдачей Заказчиком дополнительного продукта "ВСК"',
      Consience:'Привлечение Клиентов, с последующей выдачей Заказчиком дополнительного продукта "Забота"',
      Insurance:'Привлечение Клиентов, с последующей выдачей Заказчиком дополнительного продукта "Страхование жизни"',
      Absolut:'Привлечение Клиентов, с последующей выдачей Заказчиком дополнительного продукта "2в1 страхование от "Абсолют страхование"',
      debitCardAlfa:'Привлечение Клиентов, с последующей выдачей Заказчиком дополнительного продукта "Кредитная карта Банка"',
      debitCardTinkoff:'Доставка документов по дополнительному продукту "Кредитная карта Банка" от Клиента к Заказчику/ от Заказчика к Клиенту',
      AlfaOrder:'Привлечение Клиентов, с последующей выдачей Заказчиком дополнительного продукта "Дебетовая карта Банка"',
      AlfaDeliverry:'Привлечение Клиентов, с последующей выдачей Заказчиком дополнительного продукта «страховка к кредитной карте Банка"',
      AddMCollect:'Консультации Клиентов Заказчика о порядке осуществления  платежей в пользу Заказчика',
      AddMSale:'Доставка документов от Клиента к Заказчику/ от Заказчика к Клиенту, найденному Заказчиком, с последующей выдачей Заказчиком займов',
      AddMAttract:'Привлечение новых Клиентов, с последующей выдачей Заказчиком займов на срок 52нед.',
      rewardPC:'Привлечение Клиентов, с последующей выдачей Заказчиком дополнительного продукта "Персональный Юрист", "Телемедицина", "ВСК", "Страхование жизни" или "2в1 страхование от "Абсолют страхование"',
      AddMPhone:'Привлечение Клиентов, с последующей выдачей Заказчиком дополнительного продукта "Кредитная карта Банка"',
      xz:'Привлечение Клиентов, с последующей выдачей Заказчиком дополнительного продукта "Дебетовая карта Банка"',
      AlfaCredit:'Привлечение Клиентов, с последующей выдачей Заказчиком дополнительного продукта «страховка к кредитной карте Банка"',
      CardAlfaReward:'Если исполнитель доставил в компанию документы по не менее 3 (трем) новым клиентам с которыми Заказчиком был заключен договор займа и при условии, что с даты заключения договора между сторонами и до текущей даты прошло менее 3 месяцев, за каждый доставленный комплект документов делается доплата.',
      insuranceCreditCardReward:'Если исполнитель доставил в компанию документы по не менее 5 (пяти) новым клиентам с которыми Заказчиком был заключен договор займа и при условии, что с даты заключения договора между сторонами и до текущей даты прошло более 3 месяцев, за каждый доставленный комплект документов делается доплата.',
      debitCardRenesans_DK:'',
      debitCardTinkoffReward:'При выполнении условий п 22 или 23 за каждого нового клиента, привлеченного исполнителем, которому заказчик выдал заем, дополнительно делается доплата',
      debitCardRenesans_KK:'',
    },
    Agent:{
      Collect:'Сборы по договорам клиентов',//1
      Collect10:'Сбор по договорам клиентов (акционный пул)',//2
      Collect15:'Сбор по договорам клиентов (акционный пул2)',
      CollectOnline: 'Вознаграждение за сборы онлайн (+1%)',
      Psale25:'Продажа, (25 нед.)',//3
      Psale:'Продажа, (52 нед.)',//4
      Attr:'Привлечение по персональному каналу',//5
      Attr2:'Привлечение по ПК (акционный пул 2)',
      Amulex:'Персональный юрист (Амулекс)',//6
      TeleDoc:'Телемедицина',//7
      VSK:'"ВСК"',//8
      Consience:'"Забота"',//9
      Insurance:'Страхование жизни',//10
      Absolut:'"Абсолют"',//11
      debitCardAlfa:'Дебетовая карта Альфа банк',//12
      debitCardTinkoff:'Дебетовая карта Тинькофф банк',//13
      AlfaOrder:'Кредитная карта банка (заявка)',//14
      AlfaDeliverry:'Кредитная карта банка (выдача и активация)',//15
      AddMCollect:'Сборы',//16
      AddMSale:'Продажи',//17
      AddMAttract:'Привлечение клиентов',//18
      rewardPC:'Премия ПК',//19
      AddMPhone:'Удержание аванса за мобильную связь',//20
      xz:'Дополнительные продукты',//21
      AlfaCredit:'Кредитные карты ГПБ',//22
      CardAlfaReward:'Карты Росбанк',//23
      insuranceCreditCardReward:'Страхование КК',//24
      debitCardRenesans_DK:  'Дебетовые карты Банка Сбребанк',//25
      debitCardTinkoffReward:'Дебетовые карты Тинькофф банка',//26
      debitCardRenesans_KK:  'Кредитные карты Банка Сбребанк',//27
      withheldForDPforFees: 'Удержано за ДП за сборы',//28
      withheldForDPforIssuance: 'Удержано за ДП за выдачи',//29
      withheldForDPforPK: 'Удержано за ДП за ПК',//30
      creditCardSvoyBank:'Кредитная карта Свой банк',//31
      creditCardGKB:'Кредитная карта ГКБ',//32

    }
  }
};

const getters = {
  getLangWeekReport:(state)=>{
    if (state.selfAgent === 1) {
      return state.library.selfAgent;
    } else {
      return state.library.Agent
    }
  }
};

const actions = {
  updateSelfAgent({commit},newValue){
    if (newValue !== undefined
        && newValue !== null
        && !isNaN(newValue)
        && newValue !== state.selfAgent
    ) {
      commit('setSelfAgent',parseInt(newValue,10))
    } else {
      commit('setSelfAgent',0);
    }
  }
};

const mutations = {
  setStaff_id: (state, value) => state.staff_id = value,
  setSelfAgent: (state,value) => state.selfAgent = value,
  setbwid: (state, value) => state.bwid = value,
  managerMenu: (state, value) => state.managerMenu = value,
};

export default {
  state,
  getters,
  actions,
  mutations
}


