const state = {
  portal:{
    dtBegin:'',
    dtEnd:'',
    arr:[],
  },
  lnd:{
    dtBegin:'',
    dtEnd:'',
    arr:[],
  },
  lndShrt:{
    dtBegin:'',
    dtEnd:'',
    arr:[],
  },
  insurance:{
    dtBegin:'',
    dtEnd:'',
    arr:[],
  },
  dateFilter:{
    dtBegin:'',
    dtEnd:'',
  }
};

const getters = {

};

const actions = {
  saveRequest: ({commit}, arrToSave) => {
    if (state[arrToSave.target] != undefined) {
        commit('setTargetValue',arrToSave);
    }
  },
};

const mutations = {
setDateFilter:(state, obj) => state.dateFilter[obj.target] = obj.value,
  setTargetValue: (state, obj) => {
    state[obj.target].dtBegin = obj.dtBegin;
    state[obj.target].dtEnd = obj.dtEnd;
    state[obj.target].arr = obj.arr;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
}
