import FormFieldCheck from "@/store/Form/requestFormFieldCheck";

const defAlfaState = () => {
  return {
    lastName: "",
    firstName: "",
    middleName: "",
    passport: {
      birthDate: "",
      birthPlace: "",
      series: '',
      oldSeries: '',
      oldNumber: '',
      number: '',
      issueDate: "",
      officeCode: "",
      office: ""
    },
    mobilePhone: '',
    sex: "",
    workInfo: {
      income: ''
    },
    code911: "DEFAULT",
    residentialAddress: {},
    residentialAddressUnrestricted_value: '',
    registrationAddress: {},
    registrationAddressUnrestricted_value: '',
    confCode:'',
    selfDelivery:true,
    RouteCode:null,
    resultAlfaCheckAddress:{},
    AlfaCard_Newdemand:{},
    AlfaCard_FormSaved:{},
    alfaWTF:{}
  }
};
const defAlfaCheckState = () => {
  return {
    lastName: {code: 1, msg: 'обязательное поле', ShowError: false},
    firstName: {code: 1, msg: 'обязательное поле', ShowError: false},
    middleName: {code: 1, msg: 'обязательное поле', ShowError: false},
    passport: {
      birthDate: {code: 1, msg: 'обязательное поле', ShowError: false},
      birthPlace: {code: 1, msg: 'обязательное поле', ShowError: false},
      series: {code: 1, msg: 'обязательное поле', ShowError: false},
      oldSeries: {code: 0, msg: '', ShowError: false},
      oldNumber: {code: 0, msg: '', ShowError: false},
      number: {code: 1, msg: 'обязательное поле', ShowError: false},
      issueDate: {code: 1, msg: 'обязательное поле', ShowError: false},
      officeCode: {code: 1, msg: 'обязательное поле', ShowError: false},
      office: {code: 1, msg: 'обязательное поле', ShowError: false}
    },
    mobilePhone: {code: 1, msg: 'обязательное поле', ShowError: false},
    sex: {code: 1, msg: 'обязательное поле', ShowError: false},
    workInfo: {
      income: {code: 1, msg: 'обязательное поле', ShowError: false}
    },
    code911: {code: 0, msg: '', ShowError: false},
    residentialAddress: {code: 0, msg: '', ShowError: false},
    residentialAddressUnrestricted_value: {code: 1, msg: 'обязательное поле', ShowError: false},
    registrationAddress: {code: 0, msg: '', ShowError: false},
    registrationAddressUnrestricted_value: {code: 1, msg: 'обязательное поле', ShowError: false},
    confCode: {code: 1, msg:'обязательное поле', ShowError: false},
    selfDelivery:{code: 0, msg:'', ShowError: false},
    resultAlfaCheckAddress:{code: 0, msg:'', ShowError: false},
    AlfaCard_Newdemand:{code: 0, msg:'', ShowError: false},
    AlfaCard_FormSaved:{code: 0, msg:'', ShowError: false},
    alfaWTF:{code: 1, msg: 'обязательное поле', ShowError: false},
  }
};

const state = {
  client: defAlfaState(),
  checkResult: defAlfaCheckState()
};


const getters = {
  allAlfaFormCorrect: (state) => {
    let resultCodeSumm = 0;
    for (let item in state.checkResult) {
      if (item !== 'confCode') {
        if (state.checkResult[item].code !== undefined) {
          resultCodeSumm += parseInt(state.checkResult[item].code, 10);
        } else {
          for (let itemChild in state.checkResult[item]) {
            if (state.checkResult[item][itemChild].code !== undefined) {
              resultCodeSumm += parseInt(state.checkResult[item][itemChild].code, 10);
            }
          }
        }
      }
    }
    return resultCodeSumm;
  },
  step2AlfaFormCorrect: (state) => {
    return state.checkResult.residentialAddressUnrestricted_value.code
         + state.checkResult.registrationAddressUnrestricted_value.code;
  },
  step1AlfaFormCorrect: (state) => {
    return state.checkResult.firstName.code
      + state.checkResult.lastName.code
      + state.checkResult.middleName.code
      + state.checkResult.mobilePhone.code
      // + state.checkResult.passport.birthDate.code
  },
  fieldForCheckDublicateAddressLead: (state) =>{
    return {
      residentialAddress: state.client.residentialAddressUnrestricted_value,
    }
  },
  fieldForCheckDublicateLead: (state) => {
    return {
      middleName: state.client.middleName,
      lastName: state.client.lastName,
      firstName: state.client.firstName,
      birthDate: state.client.birthDate,
    }
  },
  isAlfaAdressValid: (state) => (target) => {
    if (state.client[target].unrestricted_value === undefined || state.client[target].unrestricted_value.length === 0) {
      return 'Обязательное поле, Вы должны выбрать адрес из выпадающего списка';
    }
    if (state.client[target].unrestricted_value !== undefined && state.client[target].unrestricted_value !== state.client[target+'Unrestricted_value']) {
      return 'Вы должны выбрать адрес из выпадающего списка';
    }
    try {
      if (state.client[target].data.house == null) {
        return 'Адрес не полный, укажите город, улицу и номер дома';
      }
    } catch (e) {
      return 'Адрес не корректный';
    }
    return '';
  },
};

const actions = {
  chekField: ({commit}, newObj) => {
    newObj['status'] = (typeof FormFieldCheck[newObj.formKey] == 'function')?
      FormFieldCheck[newObj.formKey](newObj.value) :
      FormFieldCheck.isEmpty(newObj.value);
    if (newObj.parent.length > 0) {
      commit('setStateCheckAlfaChildren',newObj);
    } else {

      commit('setStateCheckAlfaRoot',newObj);
    }
  },
  updateAlfaFormValue: ({commit, dispatch}, newObj) => {
    if (newObj != undefined
      && newObj.formKey != undefined
      && newObj.parent != undefined
      && newObj.value != undefined) {
      if (newObj.parent.length > 0) {
        commit('setStateAlfaChildren', {
          parent: newObj.parent,
          formKey: newObj.formKey,
          value: newObj.value
        });
        dispatch('chekField',{
          parent: newObj.parent,
          formKey: newObj.formKey,
          value: newObj.value
        });
      } else {
        if (state.client[newObj.formKey] != undefined && state.client[newObj.formKey] != newObj.value) {
          commit('setStateAlfaRoot', {
            formKey: newObj.formKey,
            value: newObj.value
          });
          dispatch('chekField',{
            formKey: newObj.formKey,
            value: newObj.value,
            parent: ''
          });
        }
      }
    } else {
      alert('updateAlfaFormValue newObj undefined');
    }
  },
  cdShowStatusAlfa:({commit}, newObj) => {
    //показываем или прячем для поля текст ошибки
    if (newObj.parent.length > 0) {
      commit('showStatusChildAlfa', newObj)
    } else {
      commit('showStatusAlfa', newObj)
    }
  },
  alfaSetAddressError:({commit,dispatch},newObj)=>{
    dispatch('cdShowStatusAlfa',{
      key:newObj.key + 'Unrestricted_value',
      value:newObj.show,
      parent:'',
    });
    commit('setStateCheckAlfaRoot',{
      formKey:newObj.key + 'Unrestricted_value',
      status:{
        code:newObj.code,
        msg:newObj.msg
      },
    });
  },
  toggleErrorShowStatusAlfa:({commit},newObj) => {
    if (newObj.parent.length > 0) {
      commit('cdShowStatusChildAlfa', newObj)
    } else {
      commit('cdShowStatusAlfa', newObj)
    }
  },
};

const mutations = {
  resetStateAlfaForm: (state) => {
    Object.assign(state.client, defAlfaState());
    Object.assign(state.checkResult, defAlfaCheckState());
  },
  setStateAlfaRoot: (state, newVal) => state.client[newVal.formKey] = newVal.value,
  setStateAlfaChildren: (state, newVal) => state.client[newVal.parent][newVal.formKey] = newVal.value,
  setStateCheckAlfaRoot: (state, newVal) => {
    state.checkResult[newVal.formKey].code = newVal.status.code;
    state.checkResult[newVal.formKey].msg = newVal.status.msg;
  },
  setStateCheckAlfaChildren: (state, newVal) =>{
    state.checkResult[newVal.parent][newVal.formKey].code = newVal.status.code;
    state.checkResult[newVal.parent][newVal.formKey].msg = newVal.status.msg;
  },
  showStatusAlfa:(state, newVal)=>{
    state.checkResult[newVal.key].ShowError = newVal.value;
  },
  showStatusChildAlfa:(state, newVal)=>{
    state.checkResult[newVal.parent][newVal.key].ShowError = newVal.value;
  },
};


export default {
  state,
  mutations,
  getters,
  actions
}
