import './to_fm.css'
import './to_ad.css'

const state = {
  to_theme:'local',
};

const actions = {
	checkDomainName({commit}){
		let to_theme;
		switch((new URL(window.location.href)).hostname){
			case'p-fm.dfin-group.ru':
				to_theme = 'to_fm';
			break;
			case'p-ad.dfin-group.ru':
				to_theme = 'to_ad';
			break;
			default:
				to_theme = 'to_fm';
			break;
		}
		document.body.classList.add(to_theme);
		commit('saveResult', to_theme);
	}
};

const mutations = {
	saveResult:(state,value) => state.to_theme = value,
};

export default {
  state,
  actions,
  mutations
}


