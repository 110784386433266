function funcAscRowNumber (d1, d2) {
  return (d1.RowNumber > d2.RowNumber) ? 1 : -1;
}

function filterIndexOfCondition(item, filter) {
  if (filter == '') return true;
  try {
    return item.toLocaleLowerCase().indexOf(filter.toLowerCase()) >= 0;
  } catch (e) {
    return true;
  }
}

function filterStrictlyCondition(item, filter) {
  if (filter == '') return true;
  try {
    return item == filter;
  } catch (e) {
    return true;
  }
}

function applyCondition(clientList, filterFio, filterDecision, filterContactType, filterComment) {
  return clientList.filter((item) => {
    let fio = '',
      Decision = '',
      Comment = '',
      ContactType = '';

    if (item.ClLastName != undefined) {
      fio += item.ClLastName;
    }
    if (item.ClFirstName != undefined) {
      fio += item.ClFirstName;
    }
    if (item.ClMiddleName != undefined) {
      fio += item.ClMiddleName;
    }
    Decision = (item.Decision != undefined) ? item.Decision : '';
    ContactType = (item.ContactType != undefined) ? item.ContactType : '';
    Comment = (item.Comment != undefined) ? item.Comment : '';

    return filterIndexOfCondition(fio, filterFio)
      && filterIndexOfCondition(Comment, filterComment)
      && filterStrictlyCondition(Decision, filterDecision)
      && filterStrictlyCondition(ContactType, filterContactType);
  });
}

const state = {
  clientList: [],
  viewLimit: 10,
  detailClientID:0,
  detailClientArr:[],
  availableDecision: [],
  availableContactType: [],
  filterFio: '',
  filterDecision: '',
  filterContactType: '',
  filterComment:'',
};

const getters = {
  viewList: state => {
    return applyCondition(state.clientList, state.filterFio, state.filterDecision, state.filterContactType, state.filterComment)
    .sort(funcAscRowNumber)
    .slice(0, state.viewLimit);
  },
  clientListLength: state => {
    return applyCondition(state.clientList, state.filterFio, state.filterDecision, state.filterContactType, state.filterComment).length;
  },
};

const actions = {
  newClientList: ({commit}, newClientList) => {
      commit('resetAvailabel');
      newClientList.forEach((item) => {
        if (item.ContactType != undefined && item.ContactType != '') {
          if (state.availableContactType.indexOf(item.ContactType) < 0) {
            commit('addContactType', item.ContactType);
          }
        }
        if (item.Decision != undefined ) {
          if (item.Decision == '') {item.Decision = 'Нет статуса';}
          if (state.availableDecision.indexOf(item.Decision) < 0) {
            commit('addDecision', item.Decision);
          }
        }

      });
    commit('updateClientList',newClientList);
  },
  // eslint-disable-next-line no-unused-vars
  updateSingleLead:({commit}, newData) => {
    let LeadIndex = state.clientList.findIndex(item=> {
      return item.CallingListID == newData.CallingListID;
    });
    if (LeadIndex >= 0) {
      commit('updateLeadInList', {
        index:LeadIndex,
        Comment:state.clientList[LeadIndex].Comment + ' # ' + newData.NewComment,
        Decision:newData.Decision
      });
    }
  },
};

const mutations = {
  updateClientList: (state, value) => state.clientList = value,
  updateLeadInList: (state, valueObj) => {
    state.clientList[valueObj.index].Comment = valueObj.Comment;
    state.clientList[valueObj.index].Decision = valueObj.Decision;

  },
  incViewLimit: state => state.viewLimit += 10,
  setDetailClientID: (state, value) => state.detailClientID = value,
  setDetailClientArr: (state, value) => state.detailClientArr = value,
  addContactType: (state, value) => state.availableContactType.push(value),
  addDecision: (state, value) => state.availableDecision.push(value),
  resetAvailabel: (state) => {
    state.availableDecision = [];
    state.availableContactType = [];
  },
  setFilterFio: (state, value) => state.filterFio = value,
  setFilterComment: (state, value) => state.filterComment = value,
  setFilterDecision: (state, value) => state.filterDecision = value,
  setFilterContactType: (state, value) => state.filterContactType = value,
};

export default {
  state,
  getters,
  actions,
  mutations,
}
