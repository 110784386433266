import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'
import $myApi from '@/gavnoCode/ApiHandler'

Vue.use(VueRouter);


const ifAuthenticated = (to, from, next) => {
    store.dispatch('refLinkSave',{
      pathname: document.location.pathname,
      search: document.location.search,
      fromPath: from.path,
    });
//console.log('test')
  if (store.getters.isAuthenticated) {
  //  console.log('to page')
    next();
    return
  }
 // console.log('to login')
  next('/login');
};
const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return
  }
  next('/');
};

const routes = [
  {
    path: '/',
    name: 'Главная',
    component: () => import('@/views/Home'),
    beforeEnter: ifAuthenticated,
    menuView: true,
    sort: 0,
  },
  {
    path: '/oneWindowView',
    name: 'Единое окно',
    component: () => import('@/views/oneWindowView/oneWindowComponent'),
    beforeEnter: ifAuthenticated,
    menuView: true,
    sort: 150,
    accessGroup:[262,281,350,389,713,763,355,757,495],
  },
  {
    path: '/login',

    component: () => import('@/views/index'),
    beforeEnter: ifNotAuthenticated,
    props: { id: "loginWrap"},
    children: [
      {path: '',             name: 'login',        component: () => import('@/views/login/LoginForm'),                   },
      {path: 'recovery',     name: 'recovery',     component: () => import('@/views/login/RecoveryPassword'),            },
      {path: 'recoveryFrst', name: 'recoveryFrst', component: () => import('@/views/login/RecoveryPasswordManagerCode'), },
    ]
  },
  {
    path: '/logout',
    name: 'Выход',
    component: () => import('@/views/login/logOut'),
    menuView: true,
    sort: 1000,
    beforeEnter: ifAuthenticated,
  },
  {
    path:'/docs',
    name:'docs',
    menuView:false,
    sort:0,
    component: () => import('@/views/index'),
    children:[
      {path:'iu/:demand',               name:'iu',                    component:()=>import('@/components/documents/iu'),                    props:true},
      {path:'topUpApplication/:demand', name:'topUpApplication',      component:()=>import('@/components/documents/topUpApplication'),      props:true},
      {path:'topUpReceipt/:demand',                                                                                                         props:true},
      {path:'dealAkt/:demand',          name:'dealAct',               component:()=>import('@/components/documents/dealAkt'),               props:true},
      {path:'dealPaySchedule/:demand/:mode',  name:'dealPaySchedule',       component:()=>import('@/components/documents/dealPaySchedule'),       props:true},
      {path:'dealPayScheduleUPR/:demand',  name:'dealPayScheduleUPR',       component:()=>import('@/components/documents/dealPayScheduleUPR'),       props:true},
      {path:'debtReference/:demand',    name:'Справка об отсутствии задолженности ',         component:()=>import('@/components/documents/debtReference'),         props:true},
      {path:'debtReferenceShort/:demand',    name:'Справка об отсутствии задолженности',         component:()=>import('@/components/documents/debtReferenceShort'),         props:true},
      {path:'dealPayBook/:demand',      name:'dealPayBook',           component:()=>import('@/components/documents/dealPayBook'),           props:true},
      {path:'NoticeOfDelay/:demand',    name:'OWDPrintNoticeOfDelay', component:()=>import('@/components/documents/OWDPrintNoticeOfDelay'), props:true}
    ]
  },
  {
    path:'/request',
    name:'Новая заявка',
    menuView:true,
    sort: 50,
    beforeEnter: ifAuthenticated,
    component:()=>import('@/views/index'),
    children:[
      {path:'loan',      name:'Заявка на займ',         component:()=>import('@/views/requests/Loan'),      menuView: true,},
      {path:'insurance', name:'Заявка на страхование', component:()=>import('@/views/requests/Insurance'), menuView: true,},
    ]
  },
  { path: '/insurance', redirect: '/request/insurance' },
  {
    path:'/news/list',
    name:'Новости',
     component:()=>import('@/views/index'),
    // component:()=>import('@/views/news/page'),
    beforeEnter: ifAuthenticated,
    children:[
      {path:'/news/list',    name:'Cписок новостей', component:()=>import('@/views/news/page'),      beforeEnter: ifAuthenticated, menuView: false,},
      {path:'/news/:newsid', name:'Новость',         component:()=>import('@/views/news/page_node'), beforeEnter: ifAuthenticated, menuView: false,}
    ],
    //accessGroup:[355],
    menuView: true,
    sort: 20,
  },
  {
    path: '/myRequest',
    name: 'Мои заявки',
    component: () => import('@/views/myRequest/myRequest'),
    beforeEnter: ifAuthenticated,
    menuView: true,
    sort: 90,
    children: [
      {path: 'loan',      name:'Заявки портал',                       component: () => import('@/views/myRequest/myRequestPortal'),    menuView: true, },
      {path: 'lnd',       name:'Заявки реферальная ссылка',           component: () => import('@/views/myRequest/myRequestLnd'),       menuView: true,},
      {path: 'lndShrt',   name:'Заявки(короткие) реферальная ссылка', component: () => import('@/views/myRequest/myRequestLndShrt'),   menuView: false,},
      {path: 'insurance', name:'Заявки страхования',                  component: () => import('@/views/myRequest/myRequestInsurance'), menuView: true, },
    ],
  },
  {
    path: '/agentCC',
    name: 'Исходящий звонок',
    component: () => import('@/views/agentCC/agentCC'),
    beforeEnter: ifAuthenticated,
    menuView: true,
    sort: 10,
    children:[
      {path: '',       component: () => import('@/components/agentCC/list'),  name: 'Исходящий звонок', menuView: false,},
      {path: 'detail', component: () => import('@/components/agentCC/detailClient'),                    menuView: false,},
    ],
    accessGroup: [355,713,763,389,281,350,262,343]
  },
  {
    path: '/hls',
    name:'dashStream',
    component: ()=> import('@/views/stream/hls'),
    beforeEnter: ifAuthenticated,
  },
  {
    path:'/marketing',
    name:'Маркетинг',
    component:()=>import('@/views/index'),
    beforeEnter: ifAuthenticated,
    children:[
      {path: 'lndCC', name:'Отчет лендинг партнеры КЦ', component: () => import('@/views/marketing/lndCC'), menuView: true,},
    ],
    accessGroup:[355,242],
    menuView: true,
    sort: 110,
  },
  {
    path: '/menupage',
    name: 'menupage',
    component: () => import('@/views/menuPage'),
    beforeEnter: ifAuthenticated,
  },
  {
    path:'/supervisor',
    name:'Cупервайзер',
    component:()=>import('@/views/index'),
    children:[
      {path:'rpk',name:'Списки РПК',component:()=>import('@/views/supervisor/rpk'),menuView: true,}
    ],
    beforeEnter: ifAuthenticated,
    accessGroup:[355,262,281],
    menuView: true,
    sort: 120,
  },
  {
    path:'/requestConfirm',
    name:'Оформление займа',
    component:()=>import('@/views/requestConfirm/requestRouting'),
    children:[
      {path:'list', name:'Список менеджер оповещен', component:()=>import('@/views/requestConfirm/requestList'),menuView:true  },
      {path:'terminal', name:'Рабочее окно', component:()=>import('@/views/requestConfirm/workTerminal'), menuView:true  },
      {path:'ConfirmationOfIssue/:demand', name:'Подтверждение выдачи на 25 недель', component:()=>import('@/views/requestConfirm/terminal/ConfirmationOfIssue'), menuView:false },
      {path:'myList',                      name:'Заявки в работе',                   component:()=>import('@/views/requestConfirm/requestListSave'),              menuView:true  },
      {path:'demand/:demand',              name:'Заявка',                            component:()=>import('@/views/requestConfirm/requestDemandEdit'),            menuView:false, props: true},
      {path:'income',                      name:'Прием заявки от КЦ',                component:()=>import('@/views/requestConfirm/requestCCincome'),              menuView:true  }
    ],
    beforeEnter: ifAuthenticated,
    menuView: true,
    sort: 130,
    accessGroup: [355,713,763,389,281,350,262,343]
  },
  {
    path:'/alfa',
    name:'Выдача карт Альфа Банка',
    component:()=>import('@/views/alfa/alfaRouting'),
    children:[
      {path:'alfaFaq',               name:'Вопрос/ответ',             component:()=>import('@/views/alfa/faq'),menuView: true,},
      {path:'alfaRequest',           name:'Новая заявка Альфа карты', component:()=>import('@/views/alfa/alfaNewRequest'),menuView: true,},
      //{path:'newAlfa',             name:'Новая заявка альфа карты', component:()=>import('@/views/alfa/alfaRequestNew'),menuView: false,},
      //{path:'alfaRequest/:demand', name:'заявка альфа карты',       component:()=>import('@/views/alfa/alfaRequestProgress'),menuView: false,},
      {path:'alfaList',              name:'Заявки Альфа в работе',    component:()=>import('@/views/alfa/alfaList'),menuView: true,},
      {
        path:'alfaListStaffing/creditCard',
        name:'Cписок заявок Альфа, кредитная карта',
        component:()=>import('@/views/alfa/alfaGridStaffing'),
        menuView: true,
        sort: 140,
        accessGroup:[355,262,281,350,389,713,714,763],
      },
      {
        path:'alfaListStaffing/debitCard',
        name:'Cписок заявок Альфа, дебетовая карта',
        component:()=>import('@/views/alfa/alfaGridStaffingDebet'),
        menuView: true,
        sort: 150,
        accessGroup:[355,262,281,350,389,713,714,763],
      },
    ],
    beforeEnter: ifAuthenticated,
    menuView: false,
    sort: 160,
  },
  {
    path:'/bankCard',
    name:'Партнёрский канал',
    component:()=>import('@/views/index'),
    children:[
          // {
          //   path:'',
          //   name:'Оформить заявку на кредитную карту, Банк Ренессанс Кредит',
          //   // name:'Оформить заявку на кредитную (дебетовую) карту, Банк Ренессанс Кредит',
          //   component:()=>import('@/views/bankCard/renesans/newCard'),
          //   menuView: true,
          //   accessGroup:[0]
          // },
      {
        path:'report',
        name:'Отчет по заявкам',
        component:()=>import('@/views/bankCard/report'),
        menuView: true,
        // accessGroup:[355,262,281,350,389,713,714,763,592,273],
      }
    ],
    menuView: true,
    sort:160,
  },
  {
    path:'/motivation',
    name:'Мотивация',
    component:()=>import('@/views/motivation/motivation'),
    children:[
      {path:'agentMotivation',            name:'Отчет агента за неделю',                         component:()=>import('@/views/motivation/weekReport'),                  menuView: true,},
      {path:'RewardForCollected',         name:'Детализация вознаграждения за сборы',            component:()=>import('@/views/motivation/RewardForCollected'),          menuView: true,},
      {path:'RewardForSales',             name:'Детализация вознаграждения за продажи',          component:()=>import('@/views/motivation/RewardForSales'),              menuView: true,},
      {path:'RewardForCustomerAttraction',name:'Детализация вознаграждения за ПК',               component:()=>import('@/views/motivation/RewardForCustomerAttraction'), menuView: true,},
      {path:'RewardForBoxes',             name:'Детализация вознаграждения за доп.продукты',     component:()=>import('@/views/motivation/RewardForBoxes'),              menuView: true,},
      {path:'DealReward',                 name:'Поиск оплаты вознаграждения по номеру договора', component:()=>import('@/views/motivation/DealReward'),                  menuView: true,},
      {path:'KgList',                     name:'Портфель текущей недели',                        component:()=>import('@/views/motivation/KgList'),                      menuView: true,},
      // {path:'AlfaList', name:'Вознаграждение за карты Банка', component:()=>import('@/views/motivation/alfalist'),menuView: false,},
      // {path:'AlfaFinder', name:'Поиск информации по карте Банка', component:()=>import('@/views/motivation/AlfaFinder'),menuView: false,},
      {path:'bankReport', name:'Поиск информации по карте Банка', component:()=>import('@/views/motivation/bankReport'),menuView: true,},
    ],
    beforeEnter: ifAuthenticated,
    menuView: true,
    sort: 170,
  },
  {
    path:'/motivationRepots',
    name:'Мотивация отчеты',
    component:()=>import('@/views/motivationReports/MotivationReports'),
    beforeEnter: ifAuthenticated,
    menuView: true,
    accessGroup:[355,592],
    sort: 175,
  },
  {
    name:'testComponents',
    path:'/testComponents',
    component:()=>import('@/views/devTestComponents/ship'),
  },
  {
    name: 'Журналы',
    path: '/grid',
    menuView: true,
    sort: 180,
    children: [
      {name: 'Реферальные ссылки',     path:'refLinksGreed',    component:()=>import('@/views/grid/refLinkGreed.vue'),    menuView: true,accessGroup: [355,713,763,389,281,350,262,99,757,343,495]},
      {name: 'Контроль оригиналов',     path:'originalsCheck',    component:()=>import('@/views/grid/originalsCheck'),    menuView: true,accessGroup: [355,713,763,389,281,350,262,99,757,495]},
      // {name: 'Судебное взыскание',     path:'judicialRecoveryComponent',    component:()=>import('@/views/grid/judicialRecoveryComponent'),    menuView: true,accessGroup: [355, 99, 149, 493]},
      {name: 'Платежи',     path:'payment',    component:()=>import('@/views/grid/payment'),    menuView: true,accessGroup: [355,713,763,389,281,350,262,757,827,495]},
      {name: 'Заявки',      path:'demandList', component:()=>import('@/views/grid/demandList'), menuView: true,accessGroup: [355,713,763,389,281,350,262,99,757,827,495]},
      {name: 'Договора',    path:'deal',       component:()=>import('@/views/grid/deal'),       menuView: true,accessGroup: [355,713,763,389,281,350,262,99,757,827,495]},
      {name: 'Организации', path: 'org',       component:()=>import('@/views/grid/org'),        menuView: true},
      {name: 'Сотрудники', path:'employee', component:()=>import('@/views/grid/employee'), menuView:true,accessGroup:[355,763,713,389,350,262,281,757,495]},
      {name: 'Листы сбора', path:'collectionList', component:()=>import('@/views/grid/collectionList'), menuView:true,accessGroup:[355,763,713,389,350,262,281,757,827,495]},
      {name: 'Листы сбора PDL',     path:'PDL_list',    component:()=>import('@/views/grid/PDL_list'),    menuView: true,accessGroup: [355,713,763,389,281,350,262,757,495]},
      ],
    beforeEnter: ifAuthenticated,
    component:()=>import('@/views/index'),
    accessGroup: [355,713,763,389,281,350,262,343,99,149,493,757,827,495]
  },
  {
    name:'Обучение',
    path:'/education',
    menuView:true,
    sort:190,
    component:()=>import('@/views/education/education')
  },
  {
    name:'Карта участков',
    path:'/yamaps',
    menuView:true,
    sort:200,
    component:()=>import('@/views/yandex/map'),
    beforeEnter: ifAuthenticated,
    accessGroup: [355,713,763,389,281,350,262,343,99,757,365,273]
  },
  {
   name:'Оплата',
   path:'/payment',
   menuView:true,
   sort:210,
   component:()=>import('@/views/payment/routing')
  },
  {
    name: 'Телефонное взыскание',
    path: '/phonePunishment',
    menuView: true,
    sort: 220,
    component: () => import('@/views/grid/phonePunishment'),
    accessGroup:[355,516,757,365,495],
  },
  {
    name: 'Детальное представление',
    path: '/phonePunishment/:demandId',
    menuView: false,
    sort: 220,
    component: () => import('@/views/oneWindowView/oneWindowDetails'),
    accessGroup:[355,516,757,365],
  },
  {
    name: 'Верификация',
    path: '/verification',
    menuView: true,
    sort: 230,
    component: () => import('@/views/index'),
    accessGroup:[355,757,365,272,310,823,495],
    children: [{
      name: 'Верификация заявок PDL',
      path:'verificationPDL',
      component:()=>import('@/views/verification/verificationPDL'),
      menuView: true,
      accessGroup: [355,757,365,272,310,823,495]
    },
      {
        name: 'Детальный просмотр заявки PDL',
        path:'loanPDL/:demand',
        component:()=>import('@/views/verification/verificationPDLdetail'),
        menuView: false,
        props: true,
        accessGroup: [355,757,365,272,310,823,495]
      }
    ]
  },
  {
    path:'/agentCCout',
    name:'Рабочее место агента по обзвону',
    menuView:true,
    sort: 240,
    beforeEnter: ifAuthenticated,
    component:()=>import('@/views/agentCCout/agentCCout.vue'),
    children:[
      {path:'grid',     name:'Реестр обзвона',         component:()=>import('@/views/agentCCout/grid.vue'),  menuView: true,},
      {path: 'import',  name: 'Загрузка реестра', component:()=>import('@/views/agentCCout/import.vue'), menuView: true,},
      {path: 'archive',  name: 'Архив', component:()=>import('@/views/agentCCout/archive.vue'), menuView: true,},
      {path: 'statistic',  name: 'Статистика', component:()=>import('@/views/agentCCout/statistic.vue'), menuView: true,},
      {path: 'instructions',  name: 'Инструкции', component:()=>import('@/views/agentCCout/instructions.vue'), menuView: true,},
    ]
  },
  {
    path:'/payNumberCard',
    name:'Список карт оплаты',
    menuView: true,
    sort:250,
    beforeEnter:ifAuthenticated,
    component:()=>import('@/views/payCard/numbersPayCard.vue')
  },
  {
    path: '*',
    name: 'error404',
    component: () => import('@/views/404'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.afterEach((to, from) => {
  let strTitle = to.name;

  if (to.params !== undefined && to.params.demand !== undefined){
    strTitle += ' №' + to.params.demand;
  }
  document.title = strTitle;




  $myApi.singleRequest({
      action:'metrik_pageHit',
      value:{
        fromPage:from.fullPath,
        toPage:to.fullPath,
      },
    }).then(res=>{
      if (res != undefined) {
        res['asd'] = 'asd';
      }
    }).catch(res=>{
      if (res != undefined) {
        res['asd'] = 'asd';
      }
    });
});

export default router
