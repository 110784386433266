const state = {
  isDeviceNavigatorIsset: false,
  geoState: {
    latitude: null,
    longitude: null,
    altitude: null,
    accuracy: null,
    altitudeAccuracy: null,
    heading: null,
    speed: null,
  },
  geoErrorCode:-1,
};

const getters = {};

const actions = {
  geoInit: ({commit, dispatch}) => {
    let isNavigatorIsset = (navigator.geolocation) ? true : false
    commit('DeviceNavigatorIsset', isNavigatorIsset);
    if (isNavigatorIsset) {
      navigator.geolocation.watchPosition(
        (pos) => {
          dispatch('geoSuccessWatch', pos.coords);
        },
        (err) => {
          commit('updateGeoErrorCode', err.code);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 10000
        });
    }
  },
  geoSuccessWatch: ({commit, state}, pos) => {
    let geoStateArrKeys = Object.keys(state.geoState);
    geoStateArrKeys.forEach((index) => {
      if (pos[index] != null) {
        commit('setGeoState', {
          index: index,
          value: pos[index]
        });
      }
    });
    commit('updateGeoErrorCode', 0);
  },
};

const mutations = {
  DeviceNavigatorIsset: (state, value) => state.isDeviceNavigatorIsset = value,
  setGeoState: (state, objValue) => state.geoState[objValue.index] = objValue.value,
  updateGeoErrorCode:(state,value)=> state.geoErrorCode = value,
};

export default {
  state,
  getters,
  actions,
  mutations,
}
