export default function handleError(jsonData, router, title) {
  try {
    if (jsonData.Code === 401) {
      router.push('/logout');
      return;
    } else if (jsonData.Code !== 0) {
      alert(`Method: ${ title }, Error description: ${ jsonData.Message }, Error ${ jsonData.Code }`);
    }
  } catch (error) {
    alert(`Method: ${ title }, Error description: ${ error }`);
  }
}
