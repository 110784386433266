export default {
  removeLetters: function (value) {return  value.replace(/\D/g, '')},
  removeAllNotCyrillicLetters: function(value)  {
    let outStr = '';
    for (let i = 0; i < value.length; i += 1) {
      if (/[а-яА-Я ]/g.test(value[i])) {
        outStr += value[i]
      }
    }
    return outStr;
  },
  filterManagerCode: function (value) {
    return this.removeLetters(value).substring(0, 7)
  },
  uuidv4: function () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  },
  correctDate: function (value) {
    //проверка нормальная ли дата для передачи в бек.
    let curDate = new Date();
    let out = true;
    if (/^([0-9]{4,4})+\.([0-9]{2,2})+\.[0-9]{2,2}$/.test(value)) {
      out = false;
    }
    if (value.length > 10) {
      out = false;
    }
    let valueArr = value.split('-');
    if (valueArr[0] > (curDate.getFullYear() + 1) || valueArr[0] < '1930') {
      out = false;
    }
    return out;
  },
  formatDate(date){
    //из js даты в приемлимое для бекенда
    try {
      let values = [date.getDate(), date.getMonth() + 1];
      for (let id in values) {
        values[ id ] = values[ id ].toString().replace(/^([0-9])$/, '0$1');
      }
      return date.getFullYear() + '-' + values[1] + '-' + values[0];
    } catch (e) {
      return '0000-00-00';
    }

  },
  checkAdress: function (strValue, jsonValue) {
    try {
      return (strValue.length == 0 && jsonValue.value.length == 0) ?
        'Обязательное поле, Вы должны выбрать адрес из выпадающего списка' : (strValue != jsonValue.value) ?
          'Вы должны выбрать адрес из выпадающего списка' : (jsonValue.data.house == null) ?
            'Адрес не полный, укажите город, улицу и номер дома' : '';
    } catch (e) {
      return 'Адрес не корректный';
    }
  },
  filterStrictlyCondition(item, filter) {
    if (filter == '') return true;
    try {
      return item == filter;
    } catch (e) {
      return true;
    }
  },
  funcAscRowNumber(d1, d2) {
    return (d1.RowNumber > d2.RowNumber) ? 1 : -1;
  },
  filterIndexOfCondition(item, filter) {
    if (filter == '') return true;
    try {
      return String(item).toLocaleLowerCase().indexOf(filter.toLowerCase()) >= 0;
    } catch (e) {
      return true;
    }
  },
}
