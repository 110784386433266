import store from '@/store';

export default {
  PASSPORT_SERIES: function (value) {
    if (value.length === 4) {
      // this.focusInput('PASSPORT_NUMBER');
      return {code: 0, msg: '', ShowError: false};
    } else {
      if (value.length === 0) {
        return {code: 1, msg: 'Обязательное поле', ShowError: true};
      } else {
        return {code: -1, msg: 'Не правильный номер', ShowError: true};
      }
    }
  },
  PASSPORT_NUMBER: function (value) {
    if (value.length === 6) {
      // this.focusInput('PASSPORT_DATE');
      return {code: 0, msg: '', ShowError: false};
    } else {
      if (value.length === 0) {
        return {code: 1, msg: 'Обязательное поле', ShowError: true};
      } else {
        return {code: -1, msg: 'Не правильно записан номер', ShowError: true};
      }
    }
  },
  PASSPORT_DATE: function (value) {
    return this.correctDate(value);
  },
  PASSPORT_DEP_CODE: function (value) {
    if (value.length === 7) {
      return {code: 0, msg: '', ShowError: false};
    } else {
      if (value.length === 0) {
        return {code: 1, msg: 'Обязательное поле', ShowError: true};
      } else {
        return {code: -1, msg: 'Не правильно записан код', ShowError: true};
      }
    }
  },
  // ClBirthDate: function (value) {
  //   return this.correctDate(value);
  // },
  BIRTHDAY: function (value) {
    return this.correctDate(value);
  },
  Birthday: function (value) {
    return this.correctDate(value);
  },
  OldPassportDate: function (value) {
    return this.correctDate(value);
  },
  PassportDate: function (value) {
    return this.correctDate(value);
  },
  FirstName: function (value) {
    return this.checkFIOlength(value)
  },
  MiddleName: function (value) {
    return this.checkFIOlength(value)
  },
  LastName: function (value) {
    return this.checkFIOlength(value)
  },
  checkFIOlength: function (value) {
    if (value.length <= 49) {
      return {code: 0, msg: '', ShowError: false}
    } else {
      return {code: -1, msg: 'допустимо не более 50-ти символов', ShowError: true}
    }
  },
  MEETING_DATE: function (value) {
    let out =  this.correctDate(value);
    if (out.code == 0) {
      const inputDate = new Date(value).toISOString().slice(0, 10);
      const currentDate = new Date().toISOString().slice(0, 10);

      if (inputDate < currentDate) {
        return {code: -1, msg: 'Не корректная дата, не может быть меньше текущей', ShowError: true}
      }
    }
    return out;
  },
  MEETING_HOUR: function (value) {
    if (value.length == 0) {
      return {code: 1, msg: 'Обязательное поле', ShowError: true};
    }
    return this.correctTime(value);
  },
  CALL_DATE: function (value) {
    let out =  this.correctDate(value);
    if (out.code == 0) {
      const inputDate = new Date(value).toISOString().slice(0, 10);
      const currentDate = new Date().toISOString().slice(0, 10);

      if (inputDate < currentDate) {
        return {code: -1, msg: 'Не корректная дата, не может быть меньше текущей', ShowError: true}
      }
    }
    return out;
  },
  CALL_HOUR: function (value) {
    if (value.length == 0) {
      return {code: 1, msg: 'Обязательное поле', ShowError: true};
    }
    return this.correctTime(value);
  },
  isEmpty: function (value) {

    if (value.length === 0) {
      return {code: 1, msg: 'Обязательное поле', ShowError: true};
    } else {
      return {code: 0, msg: '', ShowError: false};
    }

  },
  IS_ADDRESS_COINCIDES: function (value) {
    if (value == 'Y' || value == 'N') {
      return {code: 0, msg: '', ShowError: false};
    } else {
      return {code: 1, msg: 'Обязательное поле', ShowError: true};
    }
  },
  correctDate: function (value) {
    // console.log(value)
    //todo разница лет, для проверки минимального и максимального возрасата заявки
    let out = {code: 1, msg: '', ShowError: false};
    if (!/^([0-9]{4,4})+\.([0-9]{2,2})+\.[0-9]{2,2}$/.test(value)) {
      out = {code: 0, msg: '', ShowError: false};
    }
    if (value.length > 10) {
      out = {code: -1, msg: 'Не корректная дата', ShowError: true}
    }
    let valueArr = value.split('-');
    if (parseInt(valueArr[0], 10) > new Date().getFullYear() || parseInt(valueArr[0], 10) < new Date().getFullYear() - 100) {
      out = {code: -1, msg: 'Не верно указан год', ShowError: true}
    }

    return out;
  },
  correctTime: function (value) {
    let out = {code: 0, msg: '', ShowError: false};
    if (!/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(value)) {
      out = {code: -1, msg: 'Не корректное время', ShowError: true};
    }
    return out;
  },
  focusInput: function (target) {
    let domEl = document.getElementsByName(target);
    if (domEl.length > 0) {
      domEl[0].focus();
    }
  },
  // PHONEmaskKostil:function(value){
  //   console.log(value);
  //   return value;
  // },
  FIRST_NAMEmaskKostil: function (value) {
    return this.fioSprMask(value);
  },
  LAST_NAMEmaskKostil: function (value) {
    return this.fioSprMask(value);
  },
  SECOND_NAMEmaskKostil: function (value) {
    return this.fioSprMask(value);
  },
  CAR_NUMBERmaskKostil: function (val) {
    //сканим строку с номером, проверяем по индексу в строку на соответсвие правилам, если все ок формируем новую строку, исходную проебываем.
    let newVal = '',
      libSwitchEn = ['A', 'B', 'E', 'K', 'M', 'H', 'O', 'P', 'C', 'T', 'Y', 'X'],
      libSwitchRu = ['А', 'В', 'Е', 'К', 'М', 'Н', 'О', 'Р', 'С', 'Т', 'У', 'Х'],
      numberSymbol = /^[0-9]$/;
    val = val.toUpperCase();
    // console.log(val)
//todo переписать на покрасивще!
    for (let i = 0; i < val.length; i += 1) {
      switch (i) {
        case 0:
          if (libSwitchEn.indexOf(val[i]) > 0) {
            newVal += libSwitchRu[libSwitchEn.indexOf(val[i])];
          }
          if (libSwitchRu.indexOf(val[i]) > 0) {
            newVal += val[i];
          }
          break;
        case 1:
          if (numberSymbol.test(val[i])) {
            newVal += val[i]
          }
          break;
        case 2:
          if (numberSymbol.test(val[i])) {
            newVal += val[i]
          }
          break;
        case 3:
          if (numberSymbol.test(val[i])) {
            newVal += val[i]
          }
          break;
        case 4:
          if (libSwitchEn.indexOf(val[i]) > 0) {
            newVal += libSwitchRu[libSwitchEn.indexOf(val[i])];
          }
          if (libSwitchRu.indexOf(val[i]) > 0) {
            newVal += val[i];
          }
          break;
        case 5:
          if (libSwitchEn.indexOf(val[i]) > 0) {
            newVal += libSwitchRu[libSwitchEn.indexOf(val[i])];
          }
          if (libSwitchRu.indexOf(val[i]) > 0) {
            newVal += val[i];
          }
          break;
        case 6:
          if (numberSymbol.test(val[i]) && parseInt(val[i], 10) > 1) {
            newVal += val[i];
          }
          break;
        case 7:
          if (numberSymbol.test(val[i])) {
            newVal += val[i]
          }
          break;
        case 8:
          if (numberSymbol.test(val[i])) {
            newVal += val[i]
          }
          break;
      }
    }
    // console.log(newVal)
    return newVal;
  },
  fioSprMask: function (value) {
    let mapKey = {
      'q': 'й',
      'w': 'ц',
      'e': 'у',
      'r': 'к',
      't': 'е',
      'y': 'н',
      'u': 'г',
      'i': 'ш',
      'o': 'щ',
      'p': 'з',
      '[': 'х',
      ']': 'ъ',
      'a': 'ф',
      's': 'ы',
      'd': 'в',
      'f': 'а',
      'g': 'п',
      'h': 'р',
      'j': 'о',
      'k': 'л',
      'l': 'д',
      ';': 'ж',
      'z': 'я',
      'x': 'ч',
      'c': 'с',
      'v': 'м',
      'b': 'и',
      'n': 'т',
      'm': 'ь',
      'Q': 'Й',
      'W': 'Ц',
      'E': 'У',
      'R': 'К',
      'T': 'Е',
      'Y': 'Н',
      'U': 'Г',
      'I': 'Ш',
      'O': 'Щ',
      'P': 'З',
      '{': 'Х',
      '}': 'Ъ',
      'A': 'Ф',
      'S': 'Ы',
      'D': 'В',
      'F': 'А',
      'G': 'П',
      'H': 'Р',
      'J': 'О',
      'K': 'Л',
      'L': 'Д',
      ':': 'Ж',
      '\'': 'Э',
      'Z': 'Я',
      'X': 'Ч',
      'C': 'С',
      'V': 'М',
      'B': 'И',
      'N': 'Т',
      'M': 'Ь',
      ',': 'Б',
      '<': 'Б',
      '.': 'Ю',
      '>': 'Ю',
    };
    let str = value.toUpperCase();
    let r = '';
    for (var i = 0; i < str.length; i++) {
      r += mapKey[str.charAt(i)] || str.charAt(i);
    }
    return r.replace(/[^А-Яа-яЁё -]/gi, '').replace(/\s+/gi, ' ').replace(/[-+]/gi, '-');
  },
  ReCallDate: function (value) {
    if (value.length == 0) {
      return {code: 0, msg: '', ShowError: false};
    } else {
      return this.correctDate(value);
    }

  },
  ReCallTime: function (value) {
    if (value.length == 0) {
      return {code: 0, msg: '', ShowError: false};
    } else {
      return this.correctTime(value);
    }
  },
  // eslint-disable-next-line no-unused-vars
  CALC_SUMM: function (value) {
    // console.log(value);
    return {code: 0, msg: '', ShowError: false};
  },
  // eslint-disable-next-line no-unused-vars
  REGISTRATION_ADDRESS_DADATA: function (value) {

    return {code: 0, msg: '', ShowError: false};
  },
  // eslint-disable-next-line no-unused-vars
  RESIDENTIAL_ADDRESS_DADATA: function (value) {

    return {code: 0, msg: '', ShowError: false};
  },
  CAR_NUMBER: function (value) {
    if (store.getters.getTargetFieldValue('PRODUCT_ID') == 'A') {
      let req = /^[АВЕКМНОРСТУХ]\d{3}[АВЕКМНОРСТУХ]{2}\d{2,3}$/ui;

      if (value.length == 0) {
        return {code: 1, msg: 'Обязательное поле', ShowError: true};
      }

      if (!req.test(value)) {
        return {code: 1, msg: 'Некорректные символы в номере', ShowError: true};
      }

      return {code: 0, msg: '', ShowError: false};
    } else {
      return {code: 0, msg: '', ShowError: false};
    }

  },
  PRODUCT_ID: function (value) {
    setTimeout(() => {
      let car_number = store.getters.getTargetFieldValue('CAR_NUMBER');
      store.commit('saveWorkData', {
        form: 'request',
        target: 'CAR_NUMBER',
        status: this.CAR_NUMBER(car_number),
      });
    }, 30);

    if (value.length == 0) {
      return {code: 1, msg: 'Обязательное поле', ShowError: true};
    }
    return {code: 0, msg: '', ShowError: false};

  },
  PHONE: function (value) {

    if (value.length == 0) {
      return {code: 1, msg: 'Обязательное поле', ShowError: true};
    }
    let chek = value.replace(/\D+/g, "");
    if (chek.length != 11) {
      return {code: 1, msg: 'Некорректный номер телефона', ShowError: true};
    }

    if (chek[0] == '7' && chek[1] == '7') {
      return {code: 1, msg: 'Некорректный код телефона', ShowError: true};
    } else if (chek[0] == '7' && chek[1] != '9') {
      return {code: 1, msg: 'Некорректный код телефона - начните ввод с цифры 9', ShowError: true};
    }
    return {code: 0, msg: '', ShowError: false};
  },
  OTHER_SOURCES_OF_INCOME: function (value) {
    if (value.length == 0) {
      return {code: 1, msg: 'Обязательное поле', ShowError: true};
    }
    return {code: 0, msg: '', ShowError: false};
  },
  CONST_INCOME: function (value) {
    if (value.length == 0) {
      return {code: 1, msg: 'Обязательное поле', ShowError: true};
    }
    return {code: 0, msg: '', ShowError: false};
  },
  officeCode:function (value) {
    return this.PASSPORT_DEP_CODE(value);
  },
  number:function (value) {
    return this.PASSPORT_NUMBER(value);
  },
  series:function (value) {
    return this.PASSPORT_SERIES(value);
  },
  issueDate:function (value) {
    let out = this.correctDate(value);
    try {
      if (out.code === 0 && (value == Intl.DateTimeFormat('en-CA').format(new Date()))) {
        out = {code: -1, msg: 'Не корректная дата', ShowError: true}
      }
    } catch (e) {
      alert('requestFormFieldCheck intl.DataTimeFormat | ' + e + ' | ' + JSON.stringify(e));
    }
    return out;
  },
  birthDate:function (value) {
    return this.correctDate(value);
  }

}
