import $external from '@/gavnoCode/external';
const activeClickObjDefault = () => {
  return {
    ID: 0,
    agents: [],
    objectIndex:[]
  }
};

const state = {
  ConfirmationList:[],
  tmpVar:{
    activeClickObj:activeClickObjDefault(), /*основная рабочая лошадка, акивный участок или территория*/
    activeEditObj:activeClickObjDefault() /*клон без связи по ссылке во время редактирования*/
  }
};
const actions = {
  yaMap_getAction_ConfirmationList:({commit})=>{
    /*
    получает массив событий на согласование
    * */
    return new Promise((resolve,reject) => {
      $external.universalRequest({
        externalType: 'zita',
        action: 'getMapObject_ConfirmationList'
      }).then(res => {
        if(res !== undefined && Array.isArray(res)) {
          commit('yaMap_set_ConfirmationList',res);
          resolve();
        } else {
          reject({
            Response:{ExecutionError:{ExecutionErrorCode:1,ExecutionErrorMessage:'Список согласования не массив'}}
          });
        }
      }).catch(res => {
        if ( res.errorCode > 0 || res.errorCode !== undefined ) {
          alert('Ошибка' + ' ' + res.errorCode + ': ' + res.errorMsg)
        }
        reject(res);
      })
    });
  },
  yaMap_setAction_activeClickObj:({commit},clickObj)=>{
    if(state.tmpVar.activeEditObj.ID > 0 && state.tmpVar.activeEditObj != state.tmpVar.activeEditObj.ID) {
      alert('Завершите редактирование участка ' + state.tmpVar.activeEditObj.Name);
      return false;
    }
    //проверяем что бы в активном объекте были все нужные поля
    const defObj =  activeClickObjDefault();
    for(let key in defObj){
      if (clickObj[key] === undefined) clickObj[key] = defObj[key];
    }
    commit('yaMap_set_ActiveClickObj_value',clickObj);
  },

};
const mutations = {
  yaMap_set_ConfirmationList:(state,newValue) => state.ConfirmationList = newValue,
  yaMap_set_ActiveClickObj_default:(state) => state.tmpVar.activeClickObj = activeClickObjDefault(),
  yaMap_set_ActiveClickObj_value:(state,value) => state.tmpVar.activeClickObj = value,
  yaMap_set_activeEditObj:(state) => state.tmpVar.activeEditObj = JSON.parse(JSON.stringify(state.tmpVar.activeClickObj)),
  yaMap_set_activeEditObj_default:(state) => state.tmpVar.activeEditObj = activeClickObjDefault(),
  // yaMap_addAgents:(state,newAgent)=>{state.tmpVar.activeClickObj.agents.push(newAgent)},
};
const getters = {
  yaMap_ConfirmationList_length:(state)=>state.ConfirmationList.length,
  yaMap_getHomeAgent:(state)=>{
    let out = [];
    const  agents = state.tmpVar.activeClickObj.agents;
      agents.forEach((item) => {
        if (item.manger_type == 1) {
          out.push(item);
        }
      });
    return out.slice(0,1);
  }
};

export default {
  state,
  mutations,
  getters,
  actions
}
