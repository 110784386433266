import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import Kostili from '@/gavnoCode/Kostili'
import myApi from '@/gavnoCode/ApiHandler'
import myFilter from './gavnoCode/filters'
import VueMask from 'v-mask'
import axios from 'axios'
import external from "@/gavnoCode/external";
import errorHandlerExternal from "@/plugins/errorHandlerExternal";
// eslint-disable-next-line
import index from "@/assets/styles/index.css";

import '@/assets/styles/index.css';


import JsonExcel from 'vue-json-excel'

Vue.component('downloadExcel', JsonExcel);


Vue.config.productionTip = false;
Vue.prototype.$kostili = Kostili;
Vue.prototype.$myFilter = myFilter;
Vue.prototype.$myApi = myApi;
Vue.prototype.$external = external;
Vue.prototype.$errorHandlerExternal = errorHandlerExternal;
for(let name in myFilter) {
  Vue.filter(name, myFilter[name]);
}
Vue.use(VueMask,{
  placeholders: {
    // eslint-disable-next-line no-useless-escape
    'Я': /[\авекмнорстухАВЕКМНОРСТУХ]/,
  }
});

axios
.post('https://portal2.msk.finmoll.com/api/getSessionId.php', {
  UserID: localStorage.getItem('UserID'),
  SessionKey: localStorage.getItem('SessionKey'),
})
.then(res => {
  if (res.data.sessionId != undefined) {
    localStorage.setItem('sessionid',res.data.sessionId)
  }
})
.catch(res => res['kostilPromise']='123')
.finally(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app');
});


